<template>
  <v-tabs>
    <v-tab>{{ $t("license.companySettings") }}</v-tab>
    <v-tab>{{ $t("license.licenseManagement") }}</v-tab>

    <v-tab-item>
      <div style="width: 100%; text-align: center; color: red" v-if="error">
        {{ this.error }}
      </div>
      <license-company-limitations @error="(e) => (error = e)" />
    </v-tab-item>

    <v-tab-item>
      <v-container fluid>
        <div style="width: 100%; text-align: center; color: red" v-if="error">
          {{ this.error }}
        </div>

        <v-text-field
          v-model="publicKey"
          prepend-icon="mdi-key"
          :label="$t('license.publicKey')"
        />
        <v-container
          class="pa-6 file-gray-hover file-drop-box"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
          v-if="licenseFile === null"
        >
          <input
            type="file"
            id="fileInput"
            name="file"
            ref="file"
            accept=".lic,.csv,.txt"
            class="file-hidden-file-input"
            @change="onChange"
          />

          <label for="fileInput">
            <div>
              {{ $t("license.upload") }}
            </div>
            <small class="file-small-hint">
              {{ $t("license.uploadHint") }}
            </small>
          </label>
        </v-container>
        <v-btn text v-else @click="clearFile">
          Click to undo file-upload
        </v-btn>
        <br />
        <v-btn
          class="mt-4"
          text
          color="primary"
          @click="uploadLicense"
          :disabled="
            licenseFile === null || publicKey == '' || publicKey == null
          "
          >{{ $t("common.add") }}</v-btn
        >

        <v-container fluid>
          <v-data-table
            :items="licenses"
            :headers="[
              { text: $t('license.fields.status'), value: 'active' },
              { text: $t('license.fields.licenseId'), value: 'licenseId' },
              {
                text: $t('license.fields.maxCompanies'),
                value: 'maxCompanies',
              },
              { text: $t('license.fields.maxGateways'), value: 'maxGateways' },
              { text: $t('license.fields.maxNodes'), value: 'maxNodes' },
              { text: $t('license.fields.expires'), value: 'expires' },
              { text: $t('gateway.fields.createdAt'), value: 'createdAt' },
              { text: '', value: 'actions' },
            ]"
          >
            <template v-slot:[`item.active`]="{ item }">
              <v-btn
                fab
                x-small
                :color="item.active ? 'success' : 'error'"
                @click="
                  () => {
                    selectedId = item.licenseId;
                    setActiveDialog = true;
                  }
                "
              >
                <v-icon
                  >{{ item.active ? "mdi-power-on" : "mdi-power-off" }}
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ humanDate(item.createdAt) }}
            </template>

            <template v-slot:[`item.expires`]="{ item }">
              {{ humanDate(item.expires, "yyyy MMM dd") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                fab
                x-small
                color="error"
                @click="
                  () => {
                    selectedId = item.licenseId;
                    confirmDelete = true;
                  }
                "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-container>

      <delete-dialog
        :dialog="confirmDelete"
        v-on:confirm-delete="remove(selectedId)"
        v-on:cancel-dialog="confirmDelete = false"
      />

      <so-dialog v-model="setActiveDialog" @accept="activate(selectedId)">
        <v-card-title>
          {{ $t("common.verify") }}
        </v-card-title>
      </so-dialog>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";
import licenseRepository from "../../api/repositories/licenseRepository";
import DeleteDialog from "../common/DeleteDialog.vue";
import SoDialog from "../common/SoDialog.vue";
import LicenseCompanyLimitations from "./LicenseCompanyLimitations.vue";

export default {
  name: "LicenseSettings",
  components: {
    LicenseCompanyLimitations,
    DeleteDialog,
    SoDialog,
  },

  data() {
    return {
      selectedId: undefined,
      confirmDelete: false,
      setActiveDialog: false,
      licenseFile: null,
      publicKey: "",
      error: undefined,
      licenses: [],
    };
  },

  computed: {
    ...mapState("users", ["currentUser"]),
  },

  methods: {
    async uploadLicense() {
      this.error = undefined;
      if (this.licenseFile == null) return;

      var formData = new FormData();
      formData.append("license", this.licenseFile);

      var result = await licenseRepository
        .uploadLicense(
          this.currentUser,
          encodeURIComponent(this.publicKey.trim()),
          formData
        )
        .then(() => true)
        .catch((e) => {
          this.error = e.response.data;
          return false;
        });

      if (!result) return;

      this.licenseFile = null;
      await this.fetchLicenses();
    },

    async activate(licenseId) {
      if (this.selectedId == undefined) return;
      this.error = undefined;
      var res = await licenseRepository
        .activate(this.currentUser, licenseId)
        .then(() => undefined)
        .catch((d) => d);

      this.selectedId = undefined;
      if (res?.response?.data === undefined) {
        await this.fetchLicenses();
        this.selectedId = undefined;
        return;
      }

      this.error = res.response.data;
      await this.fetchLicenses();
    },

    async remove(licenseId) {
      if (licenseId == undefined) return;
      this.error = undefined;
      var res = await licenseRepository
        .removeLicense(this.currentUser, licenseId)
        .then(() => undefined)
        .catch((e) => e);

      this.confirmDelete = false;
      this.selectedId = undefined;

      if (res?.response?.data === undefined) {
        await this.fetchLicenses();
        return;
      }

      this.error = res.response.data;
      await this.fetchLicenses();
    },

    clearFile() {
      this.licenseFile = null;
    },

    onChange() {
      this.error = null;
      this.licenseFile = this.$refs.file.files[0];
    },

    dragover(event) {
      event.preventDefault();

      if (!event.currentTarget.classList.contains("file-green-hover")) {
        event.currentTarget.classList.remove("file-gray-hover");
        event.currentTarget.classList.add("file-green-hover");
      }
    },

    dragleave(event) {
      this.cleanUpHover(event);
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      this.cleanUpHover(event);
    },

    cleanUpHover(event) {
      event.currentTarget.classList.add("file-gray-hover");
      event.currentTarget.classList.remove("file-green-hover");
    },

    async fetchLicenses() {
      this.licenses = await licenseRepository
        .getLicenses(this.currentUser)
        .then((d) => d.data)
        .catch(() => []);
    },
  },

  async created() {
    await this.fetchLicenses();
  },
};
</script>

<style>
.file-hidden-file-input {
  display: none;
  opacity: 0;
  width: 0px;
  height: 0px;
}

.file-drop-box {
  color: white;
  font-size: 1rem;
  letter-spacing: 0.1666666667em !important;
  line-height: 1.5rem;
  font-family: Roboto, sans-serif !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border: 1px solid rgb(175, 175, 175);
}

.file-green-hover {
  background: #17d7a0;
}

.file-gray-hover {
  background: #f2f2f2;
  color: black;
}

.file-small-hint {
  opacity: 0.7;
  font-size: 0.7rem;
}
</style>